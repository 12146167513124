<template>
  <div>
    <a class="come-back" @click.prevent="$router.go(-1)"><div></div>Вернуться назад</a>
    <h2 class="title_categories">
      {{ isEdit ? `Редактирование блога №${$route.params.id}` : 'Создание блога' }}
    </h2>
    <tricolor-line class="card_containet__tricolor"/>
    <form @submit.prevent="createBlogAdmin" ref="createBlogAdmin">
      <!-- <div class="white-container"> -->
        <div>
          <div class="content-sb">
            <p>Член ОПРФ<span>*</span></p>
            <select name="rubric" v-model="member" class="input blue" required>
              <option v-for="(item,index) in getAllMembers" :value="item.id" :key="`rubric${index}`" :selected="index === 0">
                {{ item.fullName }}
              </option>
            </select>
          </div>

          <div class="content-sb">
            <p>Рубрика<span>*</span></p>
            <select name="rubric" v-model="dataForm.rubric" class="input blue" required>
              <option v-for="(rubric,index) in getRubrics" :value="rubric.code" :key="`rubric${index}`" :selected="index === 0">
                {{ rubric.title }}
              </option>
            </select>
          </div>

          <div class="content-sb">
            <p>Статус<span>*</span></p>
            <select name="rubric" v-model="dataForm.status" class="input blue" required>
              <option v-for="(status,index) in getStatusBlogs" :value="status.code" :key="`status${index}`" :selected="index === 0">
                {{ status.title }}
              </option>
            </select>
          </div>

          <div class="content-sb">
            <p>Дата<span>*</span></p>
            <input required placeholder="Введите дату" v-model="dataForm.date" type="datetime-local" name="date_time" class="input blue">
          </div>

          <div class="content-sb tags__container">
            <p>Впишите теги через запятую</p>
            <tags @dataTags="dataTags" :startValue="dataForm.tagList"/>
          </div>

          <div class="content-sb tags__container">
            <p>Впишите теги через запятую (Англ.)</p>
            <tags @dataTags="dataTagsEng" :startValue="dataForm.tagListEng"/>
          </div>

          <div class="content-sb">
            <p>Заголовок<span>*</span></p>
            <input required v-model="dataForm.title" placeholder="Введите заголовок" type="text" name="name" class="input blue">
          </div>

          <div class="content-sb">
            <p>Заголовок (Англ.)</p>
            <input v-model="dataForm.title_eng" placeholder="Введите заголовок (Англ.)" type="text" name="name_eng" class="input blue">
          </div>

          <div class="content-sb">
            <p>Текст публикации<span>*</span></p>
            <ckeditor :config="$editorConfig" :editor-url="$root.editorUrl" v-model="dataForm.text" required></ckeditor>
          </div>

          <div class="content-sb">
            <p>Текст публикации (Англ.)</p>
            <ckeditor :config="$editorConfigEng" :editor-url="$root.editorUrl" v-model="dataForm.text_eng" required></ckeditor>
          </div>
        </div>

        <footer class="content-sb btns-create-form">
          <router-link to="/admin/blogs" class="btn-white">Отмена</router-link>
          <input type="submit" class="btn-blue" value="Сохранить"/>
        </footer>
      <!-- </div> -->
    </form>

  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'
import Tags from '@/components/Admin/Tags'
export default {
  name: 'BlogCreateAdmin',
  props: {
    isEdit: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      member: '',
      dataForm: {
        title: '',
        title_eng: '',
        text: '',
        text_eng: '',
        tagList: [],
        tagListEng: [],
        rubric: '',
        status: '',
        date: ''
      }
    }
  },
  mounted () {
    if (this.$store.getters.getAllMembers.length === 0) this.$store.dispatch('setAllMembers')
    if (this.$store.getters.getRubrics.length === 0) this.$store.dispatch('getRubrics')
    if (this.$store.getters.getStatusBlogs.length === 0) this.$store.dispatch('setStatusBlogs')
    if (!this.$store.getters.getTagsBlogs.length) this.$store.dispatch('setTagsBlogs')
    // if (this.$route.query.type === 'edit') {
    // }
    // console.log(this.isEdit, this.$route.params)
    if (this.isEdit) {
      this.$store.dispatch('setDetailBlogAdmin', { member: this.$route.params.member, post: this.$route.params.id })
    }
    if (this.$store.getters.getTagsBlogs.length === 0) this.$store.dispatch('setTagsBlogs')
  },
  computed: {
    getAllMembers () {
      return this.$store.getters.getAllMembers
    },
    getRubrics () {
      return this.$store.getters.getRubrics
    },
    getStatusBlogs () {
      return this.$store.getters.getStatusBlogs
    },
    getTagsBlogs () {
      return this.$store.getters.getTagsBlogs
    }
  },
  watch: {
    '$store.getters.getDetailBlogAdmin' () {
      const dataFromServer = this.$store.getters.getDetailBlogAdmin

      this.getAllMembers.every((item) => {
        let nameLastname = item.fullName.split(' ')
        nameLastname = `${nameLastname[1]} ${nameLastname[0]}`
        if (nameLastname === dataFromServer.full_name) {
          this.member = item.id
          return false
        } else return true
      })
      var rubric, status
      this.getRubrics.every((item) => {
        if (item.title === dataFromServer.rubric) {
          rubric = item.code
          return false
        } else return true
      })
      this.getStatusBlogs.every((item) => {
        if (item.title === dataFromServer.status) {
          status = item.code
          return false
        } else return true
      })
      console.log(dataFromServer.date)
      this.dataForm = {
        title: dataFromServer.title,
        title_eng: dataFromServer.title_eng,
        text: dataFromServer.text,
        text_eng: dataFromServer.text_eng,
        tagList: dataFromServer.tagList,
        tagListEng: dataFromServer.tagListEng,
        rubric: rubric,
        status: status,
        date: dataFromServer.date.split(' ').join('T')
      }
      this.$forceUpdate()
    }
  },
  methods: {
    createBlogAdmin () {
      const formData = new FormData()

      for (const key in this.dataForm) {
        var item = this.dataForm[key]
        if (key === 'tagList' || key === 'tagListEng') {
          formData.append(key, JSON.stringify(item))
        } else if (item) {
          formData.append(key, item)
        }
      }
      if (!this.isEdit) {
        this.$store.dispatch('createBlogAdmin', [formData, this.member])
      } else {
        this.$store.dispatch('updateBlogAdmin', [formData, this.$store.getters.getDetailBlogAdmin.id])
      }
    },
    dataTags (value) {
      this.dataForm.tagList = value
    },
    dataTagsEng (value) {
      this.dataForm.tagListEng = value
    }
  },
  components: {
    TricolorLine,
    Tags
  }
}
</script>

<style scoped lang="scss">
  .tags__container {
    position: relative;
    .tags__list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: absolute;
      width: 33.75rem;
      min-height: 2.45rem;
      right: 0;
      top: 4rem;
      box-sizing: border-box;
      border-radius: .5rem;
      background-color: white;
      box-shadow: 0 2rem 5.5rem rgba(37, 70, 103, .35);
      z-index: 100;
      padding: .625rem .9375rem;

      > .tag {
        display: inline-block;
        padding: .3125rem .75rem;
        box-sizing: border-box;
        background: #EEF4FA;
        border-radius: .25rem;
        color: #246CB7;
        font-size: .75rem;
        line-height: 1.125rem;
        margin-bottom: 0.625rem;
        cursor: pointer;
      }
    }
  }

.select, .input{
  width: 33.75rem;
}
.content-sb{
  margin-bottom: 1.25rem;
  span{
    color: red;
  }
}
.wrapper-blog{
  margin-top: 1.25rem;
}
.btns-create-form{
  border-top: 1px solid #D5D6D9;
  padding-top: 2.5rem;
  margin-top: 2.5rem;
}
</style>
